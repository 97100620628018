import Vue from "vue";
import Vuex from "vuex";
import navCollapse from "./navCollapse/index";
import useInfo from "./useInfo";
import fileUrl from './fileUrl'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    navCollapse,
    useInfo,
    fileUrl
  },
});
