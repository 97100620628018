import request from "@/utils/request";

// 登录
export const Login = (params) => request.post("/admin/login", params);

// 退出登录
export const Logout = (params) => request.post("/admin/logout", params);

// 获取用户信息
export const getUserInfo = (data) => request.post("/admin/userInfo", data);

// 订单列表
export const getOrderList = (data) => {
  return request({
    url: "/admin/orderList",
    method: "get",
    params: data,
  });
};

// export const getOrderList = (data) => request.get("/orderList", data);

// 修改状态
export const editStatus = (id, data) => {
  return request({
    url: `/admin/update/${id}`,
    method: "post",
    data,
  });
};

// 操作日志
export const getOperationList = (id, params) => {
  return request({
    url: `/admin/operationList/${id}`,
    method: "get",
    params,
  });
};

// 导出
export function getExport(params) {
  return request({
    url: `/admin/export`,
    method: "get",
    params,
    responseType: "blob",
  });
}

// 对私下载凭证
export function getVoucher(params) {
  return request({
    url: `/admin/voucherPush`,
    method: "post",
    params,
    responseType: "blob",
  });
}

// 对公下载凭证
export function getDownload(params) {
  return request({
    url: `/admin/downloadImg`,
    method: "get",
    params,
    responseType: "blob",
    // headers: [{ "Access-Control-Allow-Origin": "*" }],
  });
}

// 推送

export const getPaynotify = (params) => request.post("/admin/payNotify", params);

// export const getExport = (data) => {
//   return request({
//     url: `/export`,
//     method: "get",
//     params: data,
//   });
// };

// export const editStatus = (data) => request.post("/update", data);

// 图片
// export const uploadAvatar = (data) => request.post("/upload/files", data);
