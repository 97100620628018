import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import qs from "qs";
if (process.env.NODE_ENV === 'production') {
  if (window) {
    window.console.log = function () {};
  }
}
Vue.use(ElementUI);
Vue.use(qs);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
