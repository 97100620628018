import axios from "axios";
import router from "@/router";
import { Message, Toast } from "element-ui";
import qs from "qs";

// 环境的变化对应的url进行变化
// let axiosurl = "";
// if (process.env.NODE_ENV === "development") {
//   axiosurl = process.env.VUE_APP_BASE_API;
// } else {
//   axiosurl = process.env.VUE_APP_BASE_API;
// }

// const timeout = localStorage.getItem("expires_in");

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API + "/api",
  timeout: 100000,
});

request.interceptors.request.use(
  (config) => {
    // 获取token，然后添加到请求头上
    const access_token = localStorage.getItem("access_token");
    const token_type = localStorage.getItem("token_type");
    if (
      access_token &&
      !config.url.endsWith("/login") &&
      !config.url.endsWith("/export") &&
      !config.url.endsWith("/voucherPush")
    ) {
      config.headers.Authorization = `${token_type} ${access_token}`;
    }

    // if (config.method === "get") {
    //   console.log("get");
    //   config.paramsSerializer = function (params) {
    //     return qs.stringify(params, { arrayFormat: "repeat" });
    //   };
    // }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

request.interceptors.response.use(
  (res) => {
    console.log("回应", res);
    // const token = localStorage.getItem("access_token");
    if (res.status !== 200) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("token_type");
      localStorage.removeItem("expires_in");
      localStorage.removeItem("userInfo");
      router.push("/login");
      return Promise.reject(res.message);
      // Message.error(res.data.statusText);
    } else {
      // 所有的错误状态码直接拦截
      return res;
    }
  },
  (error) => {
    console.log("err", error);
    if (error.response.status == 401) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("token_type");
      localStorage.removeItem("expires_in");
      localStorage.removeItem("userInfo");
      // window.location.reload();
      router.push({ path: "/login" });
      return Promise.reject(error.response.data.error);
    } else if (error.message.includes("timeout")) {
      return Promise.reject(Message.error("请求超时，请稍后再试")); // reject这个错误信息
    } else if (error.response.status == 409) {
      return Promise.reject(error.response.data.error);
    } else {
      // Message.error(error.message);
      return Promise.reject(error.response.data.error);
    }
  }
);

export default request;
