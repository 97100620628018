import { getUserInfo } from "@/api";

export default {
  namespaced: true,
  state: {
    // 用户信息 做 localStorage本地存储(刷新会丢失，所以存本地)
    userInfo: JSON.parse(localStorage.getItem("userInfo")) || {
      name: null,
      email: null,
      email_verified_at: null,
      id: 2,
    },
  },
  mutations: {
    changeUserInfo(state, payload) {
      state.userInfo = payload;
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    },
  },
  actions: {
    async asyncChangeUserInfo({ commit }, payload) {
      const getUserInfoRes = await getUserInfo();
      console.log("用户信息", getUserInfoRes);
      if (!getUserInfoRes) return;
      commit("changeUserInfo", {
        name: getUserInfoRes.data.name,
        email: getUserInfoRes.data.email,
        email_verified_at: getUserInfoRes.data.email_verified_at,
        id: getUserInfoRes.data.id,
      });
    },
  },
};
