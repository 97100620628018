
import createPersistedState from 'vuex-persistedstate';

export default {
  namespaced: true,
  state: {
    fileUrl: '',
  },
  mutations: {
    changefileUrl(state, paload) {
      state.fileUrl = paload;
    },
  },

  plugins: [
    createPersistedState({
        storage: window.localStorage,
        paths: ["fileUrl"]
    })
],
};
