import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
// import { getUserInfo } from '@/api'
import getPageTitle from "@/utils/get-page-title";
import { Message } from "element-ui";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "mainlayout",
    component: () => import("../views/layout/MainLayout.vue"),
    redirect: "/order/pay",
    meta: { titles: "首页" },
    children: [
      {
        path: "/order/pay",
        name: "orderPay",
        component: () => import("../views/order/pay.vue"),
        meta: { titles: ["订单支付管理"] },
      },

      // {
      //   path: "/store/clerk",
      //   name: "clerk",
      //   component: () => import("../views/store/clerk.vue"),
      //   meta: { titles: ["门店管理", "门店店员管理"] },
      // },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue"),
  },
  {
    path: "*",
    component: () => import("../views/404.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  routes,
  base: "/",
  scrollBehavior: () => ({ y: 0 }),
});

router.beforeEach((to, from, next) => {
  document.title = getPageTitle(to.meta.titles);
  const token = localStorage.getItem("access_token");
  // 1.如果用户访问登录页面，但是有token，跳转到首页
  if (to.path === "/login" && token) {
    next("/");
    return;
  }
  // 2.如果用户访问不是登录页面，但是没有token跳转到登录页面
  if (to.path !== "/login" && !token) {
    // Message.error("未登录");
    next("/login");
    return;
  }

  next();
});

export default router;
